import { useAuth0 } from '@auth0/auth0-react';
import { CreditCardIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { PaymentMethod } from '../proto/payment_method';
import { get, post } from '../util/api';
import PaymentMethodOptionsButton from './payment-method-options-button';

export default function PaymentMethods() {
	const { getIdTokenClaims } = useAuth0();
	const [paymentMethods, setPaymentMethods] = useState<
		PaymentMethod[] | undefined
	>(undefined);
	const [primaryPaymentMethodID, setPrimaryPaymentMethodID] = useState<
		string | undefined
	>(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();

	useEffect(() => {
		(async () => {
			setLoading(true);
			setError(undefined);
			try {
				const token = await getIdTokenClaims().then((t) => t?.__raw);
				const res = await get<{ payment_methods: PaymentMethod[] }>(
					'/paymentmethods/list',
					token
				);
				setPaymentMethods(res.payment_methods);
				setPrimaryPaymentMethodID(
					res.payment_methods.find((method) => method.primary)?.id
				);
			} catch (err) {
				setError(String(err));
			}
			setLoading(false);
		})();
	}, [getIdTokenClaims]);

	const makeMethodPrimary = async (method: PaymentMethod) => {
		setPrimaryPaymentMethodID(method.id);
		try {
			const token = await getIdTokenClaims().then((t) => t?.__raw);
			await post('/paymentmethods/primary', token, {
				payment_method_id: method.id,
			});
		} catch (err) {
			// setError(String(err));
		}
	};

	const deleteMethod = async (method: PaymentMethod) => {
		setPaymentMethods(paymentMethods?.filter((m) => m.id !== method.id));
		try {
			const token = await getIdTokenClaims().then((t) => t?.__raw);
			const res = await post<{ payment_methods: PaymentMethod[] }>(
				'/paymentmethods/delete',
				token,
				{ payment_method_id: method.id }
			);
			setPaymentMethods(res.payment_methods);
		} catch (err) {
			// setError(String(err));
		}
	};

	if (error) {
		return <div>Error getting payment methods: {error}</div>;
	}

	if (loading) {
		return <div>Loading payment methods...</div>;
	}

	if (!paymentMethods || paymentMethods.length === 0) {
		return (
			<div className="text-center">
				<CreditCardIcon
					className="mx-auto h-12 w-12 text-gray-400"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					aria-hidden="true"
				/>
				<h3 className="mt-2 text-sm font-semibold text-gray-900">
					No Payment Methods
				</h3>
				<p className="mt-1 text-sm text-gray-500">
					Add a payment method to get started using Apize.
				</p>
			</div>
		);
	}

	return (
		<div className="divide-y">
			{paymentMethods.map((method, index) => (
				<div
					key={method.id}
					className={classNames({
						'py-4 flex': true,
						'pt-0': index === 0,
						'pb-0': index == paymentMethods.length - 1,
					})}
				>
					<div className="flex-auto">
						<div className="inline-block">
							<span className="font-bold">
								{method.brand.toUpperCase()}
							</span>
							&nbsp;
							<span className="font-mono">*{method.last4}</span>
						</div>
						{primaryPaymentMethodID === method.id && (
							<span className="ml-4 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
								<svg
									className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
									fill="currentColor"
									viewBox="0 0 8 8"
								>
									<circle cx={4} cy={4} r={3} />
								</svg>
								Primary
							</span>
						)}
					</div>
					<div className="flex-auto text-right">
						<PaymentMethodOptionsButton
							method={method}
							primary={primaryPaymentMethodID === method.id}
							onMakePrimary={() => makeMethodPrimary(method)}
							onDelete={() => deleteMethod(method)}
						/>
					</div>
				</div>
			))}
		</div>
	);
}

function classNames(map: { [key: string]: boolean }): string {
	return Object.keys(map)
		.filter((key) => map[key])
		.join(' ');
}
