const pricings = [
	{
		group: 'Traffic Intelligence',
		apis: [
			{
				api: 'IP Lookup',
				price: '$0.005 per request',
			},
		],
	},
	{
		group: 'Text Intelligence',
		apis: [
			{
				api: 'Summarization',
				price: '$0.04 per 100 words',
			},
			{
				api: 'Sentiment',
				price: '$0.02 per 100 words',
			},
			{
				api: 'Content Matching',
				price: '$0.02 per category, per 100 words',
			},
			{
				api: 'Grammar Correction',
				price: '$0.04 per 100 words',
			},
			{
				api: 'Grammar Explanation',
				price: '$0.03 per 100 words',
			},
		],
	},
];

export default function PricingTable() {
	return (
		<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
			<table className="min-w-full divide-y divide-gray-300">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900"
						>
							Solution
						</th>
						<th
							scope="col"
							className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							API
						</th>
						<th
							scope="col"
							className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900"
						>
							Pricing
						</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 bg-white">
					{pricings.map((group, group_index) => (
						<>
							{group.apis.map((pricing, api_index) => (
								<tr
									key={`${group_index}-${api_index}`}
									className="divide-x divide-gray-200"
								>
									{api_index === 0 && (
										<td
											rowSpan={group.apis.length}
											className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900"
										>
											{group.group}
										</td>
									)}
									<td className="whitespace-nowrap p-4 text-sm text-gray-500 border-l">
										{pricing.api}
									</td>
									<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500">
										{pricing.price}
									</td>
								</tr>
							))}
						</>
					))}
				</tbody>
			</table>
		</div>
	);
}
