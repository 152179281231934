import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CodeSnippetsCard from '../../components/code-snippets-card';
import Layout from '../../components/layout';

export default function Home() {
	const { loginWithRedirect, isAuthenticated } = useAuth0();

	return (
		<Layout nopadding>
			<Helmet>
				<title>Apize - AI Toolkit for Developers</title>
			</Helmet>
			<div className="isolate bg-white overflow-hidden">
				<div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
					<svg
						className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
						viewBox="0 0 1155 678"
					>
						<path
							fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
							fillOpacity=".3"
							d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
						/>
						<defs>
							<linearGradient
								id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
								x1="1155.49"
								x2="-78.208"
								y1=".177"
								y2="474.645"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#9089FC" />
								<stop offset={1} stopColor="#FF80B5" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<main>
					<div className="relative px-6 lg:px-8">
						<div className="mx-auto max-w-2xl py-32">
							<div className="hidden sm:mb-8 sm:flex sm:justify-center">
								<div className="relative rounded-full py-1 px-3 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
									Announcing our AI natural-language APIs.{' '}
									<Link
										to="https://docs.apize.dev/text-intelligence"
										className="font-semibold text-indigo-600"
									>
										<span
											className="absolute inset-0"
											aria-hidden="true"
										/>
										Read more{' '}
										<span aria-hidden="true">&rarr;</span>
									</Link>
								</div>
							</div>
							<div className="text-center">
								<h1 className="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-7xl">
									The AI Toolkit for Developers
								</h1>
								<p className="mt-6 text-lg leading-8 text-gray-600 mx-auto max-w-md">
									Apize delivers a powerful API for easily
									integrating artificial intelligence into
									your projects.
								</p>
								<div className="mt-10 flex items-center justify-center gap-x-6">
									<button
										onClick={() =>
											isAuthenticated
												? (document.location =
														'https://docs.apize.dev')
												: loginWithRedirect()
										}
										className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Try the API &nbsp;
										<span aria-hidden="true">&rarr;</span>
									</button>
								</div>
							</div>
							<div className="mt-10">
								<div className="mx-auto max-w-2xl">
									<CodeSnippetsCard />
								</div>
							</div>
						</div>
						<div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
							<svg
								className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
								viewBox="0 0 1155 678"
							>
								<path
									fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
									fillOpacity=".3"
									d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
								/>
								<defs>
									<linearGradient
										id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
										x1="1155.49"
										x2="-78.208"
										y1=".177"
										y2="474.645"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#9089FC" />
										<stop offset={1} stopColor="#FF80B5" />
									</linearGradient>
								</defs>
							</svg>
						</div>
					</div>
				</main>
			</div>
		</Layout>
	);
}
