import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BillsList from '../../components/bills';
import Layout from '../../components/layout';
import PaymentMethods from '../../components/payment-methods';
import UsageChart from '../../components/usage-chart';

export default function Billing() {
	return (
		<Layout>
			<Helmet>
				<title>Apize | Usage &amp; Billing</title>
			</Helmet>
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center mb-12 border-b-2 border-solid border-gray-200 pb-4">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold leading-6 text-gray-900">
							Usage &amp; Billing
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							View usage and manage bills and payments.
						</p>
					</div>
				</div>

				<div className="mb-6">
					<div className="divide-y divide-gray-200 rounded-lg bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6 bg-gray-50">
							<h1 className="text-md font-semibold leading-6 text-gray-900">
								Daily Usage (USD)
							</h1>
						</div>
						<div className="px-4 py-5 sm:p-6">
							<UsageChart />
						</div>
					</div>
				</div>

				<div className="columns-2">
					<div className="divide-y divide-gray-200 rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6 bg-gray-50 flex overflow-hidden rounded-t-lg">
							<div className="flex-auto">
								<h1 className="text-md font-semibold leading-6 text-gray-900">
									Payment Methods
								</h1>
							</div>
							<div className="flex-auto text-right">
								<Link
									to="/billing/payment-method-setup"
									className="inline justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
								>
									Add Payment Method
								</Link>
							</div>
						</div>
						<div className="px-4 py-5 sm:p-6">
							<PaymentMethods />
						</div>
					</div>

					<div className="divide-y divide-gray-200 rounded-lg bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6 bg-gray-50">
							<h1 className="text-md font-semibold leading-6 text-gray-900">
								Bills
							</h1>
						</div>
						<div className="px-4 py-5 sm:p-6">
							<BillsList />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
