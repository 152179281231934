import { useAuth0 } from '@auth0/auth0-react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
	Bars3Icon,
	BookmarkSquareIcon,
	ChatBubbleLeftRightIcon,
	CurrencyDollarIcon,
	GlobeAltIcon,
	KeyIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const solutions = [
	{
		name: 'Text Intelligence',
		description:
			'Discover and condense the meaning of natural language input.',
		href: 'https://docs.apize.dev/text-intelligence',
		icon: ChatBubbleLeftRightIcon,
	},
	{
		name: 'Traffic Intelligence',
		description: 'Unlock insights about your site traffic and customers.',
		href: 'https://docs.apize.dev/traffic-intelligence',
		icon: GlobeAltIcon,
	},
];
const callsToAction = [
	{
		name: 'API Docs',
		href: 'https://docs.apize.dev',
		icon: BookmarkSquareIcon,
	},
	{ name: 'Pricing', href: '/pricing', icon: CurrencyDollarIcon },
];
const accountLinks = [
	// {
	// 	name: 'Account',
	// 	description: 'Manage your Apize account.',
	// 	href: '/account',
	// 	icon: UserIcon,
	// },
	{
		name: 'API Tokens',
		description: 'Create and manage tokens for accessing APIs.',
		href: '/tokens',
		icon: KeyIcon,
	},
	{
		name: 'Usage & Billing',
		description: 'View usage and bills and manage payment methods.',
		href: '/billing',
		icon: CurrencyDollarIcon,
	},
];

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ');
}

export default function Header() {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
	return (
		<Popover className="fixed bg-white shadow-sm w-screen z-50">
			<div className="mx-auto max-w-7xl px-6">
				<div className="flex items-center justify-between py-4 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<Link to="/">
							<span className="sr-only">Apize</span>
							<img
								className="h-8 w-auto"
								src="/title250.png"
								alt=""
							/>
						</Link>
					</div>
					<div className="-my-2 -mr-2 md:hidden">
						<Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
							<span className="sr-only">Open menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					<Popover.Group
						as="nav"
						className="hidden space-x-10 md:flex"
					>
						<Popover className="relative">
							{({ open }) => (
								<>
									<Link
										to="/"
										className="text-gray-500 group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none"
									>
										Home
									</Link>
									<Popover.Button
										className={classNames(
											open
												? 'text-gray-900'
												: 'text-gray-500',
											'ml-12 group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none'
										)}
									>
										<span>Solutions</span>
										<ChevronDownIcon
											className={classNames(
												open
													? 'text-gray-600'
													: 'text-gray-400',
												'ml-2 h-5 w-5 group-hover:text-gray-500'
											)}
											aria-hidden="true"
										/>
									</Popover.Button>

									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
												<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
													{solutions.map((item) => (
														<Link
															key={item.name}
															to={item.href}
															className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
														>
															<item.icon
																className="h-6 w-6 flex-shrink-0 text-indigo-600"
																aria-hidden="true"
															/>
															<div className="ml-4">
																<p className="text-base font-medium text-gray-900">
																	{item.name}
																</p>
																<p className="mt-1 text-sm text-gray-500">
																	{
																		item.description
																	}
																</p>
															</div>
														</Link>
													))}
												</div>
												<div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
													{callsToAction.map(
														(item) => (
															<div
																key={item.name}
																className="flow-root"
															>
																<Link
																	to={
																		item.href
																	}
																	className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
																>
																	<item.icon
																		className="h-6 w-6 flex-shrink-0 text-gray-400"
																		aria-hidden="true"
																	/>
																	<span className="ml-3">
																		{
																			item.name
																		}
																	</span>
																</Link>
															</div>
														)
													)}
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</>
							)}
						</Popover>

						{isAuthenticated && (
							<Popover className="relative">
								{({ open }) => (
									<>
										<Popover.Button
											className={classNames(
												open
													? 'text-gray-900'
													: 'text-gray-500',
												'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none'
											)}
										>
											<span>Account</span>
											<ChevronDownIcon
												className={classNames(
													open
														? 'text-gray-600'
														: 'text-gray-400',
													'ml-2 h-5 w-5 group-hover:text-gray-500'
												)}
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
												<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{accountLinks.map(
															(item) => (
																<Link
																	key={
																		item.name
																	}
																	to={
																		item.href
																	}
																	className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
																>
																	<item.icon
																		className="h-6 w-6 flex-shrink-0 text-indigo-600"
																		aria-hidden="true"
																	/>
																	<div className="ml-4">
																		<p className="text-base font-medium text-gray-900">
																			{
																				item.name
																			}
																		</p>
																		<p className="mt-1 text-sm text-gray-500">
																			{
																				item.description
																			}
																		</p>
																	</div>
																</Link>
															)
														)}
													</div>
												</div>
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover>
						)}
					</Popover.Group>
					<div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
						{isAuthenticated ? (
							<button
								onClick={() => logout()}
								className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
							>
								Log out
							</button>
						) : (
							<>
								<button
									onClick={() => loginWithRedirect()}
									className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
								>
									Sign in
								</button>
								<button
									onClick={() => loginWithRedirect()}
									className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
								>
									Sign up
								</button>
							</>
						)}
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
				>
					<div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
						<div className="px-5 pt-5 pb-6">
							<div className="flex items-center justify-between">
								<div>
									<img
										className="h-8 w-auto"
										src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
										alt="Apize"
									/>
								</div>
								<div className="-mr-2">
									<Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
										<span className="sr-only">
											Close menu
										</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</Popover.Button>
								</div>
							</div>
							<div className="mt-6">
								<nav className="grid gap-y-8">
									{solutions.map((item) => (
										<Link
											key={item.name}
											to={item.href}
											className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
										>
											<item.icon
												className="h-6 w-6 flex-shrink-0 text-indigo-600"
												aria-hidden="true"
											/>
											<span className="ml-3 text-base font-medium text-gray-900">
												{item.name}
											</span>
										</Link>
									))}
								</nav>
							</div>
						</div>
						<div className="space-y-6 py-6 px-5">
							<div className="grid grid-cols-2 gap-y-4 gap-x-8">
								{isAuthenticated && (
									<>
										{accountLinks.map((item) => (
											<Link
												key={item.name}
												to={item.href}
												className="text-base font-medium text-gray-900 hover:text-gray-700"
											>
												{item.name}
											</Link>
										))}
									</>
								)}
							</div>
							<div>
								{isAuthenticated ? (
									<button
										onClick={() => logout()}
										className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
									>
										Log out
									</button>
								) : (
									<>
										<button
											onClick={() => loginWithRedirect()}
											className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
										>
											Sign up
										</button>
										<p className="mt-6 text-center text-base font-medium text-gray-500">
											Existing customer?{' '}
											<button
												onClick={() =>
													loginWithRedirect()
												}
												className="text-indigo-600 hover:text-indigo-500"
											>
												Sign in
											</button>
										</p>
									</>
								)}
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
}
