import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { FormEvent, useState } from 'react';

export default function CardForm() {
	const [error, setError] = useState<string | null>();

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event: FormEvent) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmSetup({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/billing/payment-method-status`,
			},
		});

		if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setError(error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<div className="text-center">
				<button
					className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 mt-6 mb-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full"
					disabled={!stripe}
				>
					Submit
				</button>
			</div>
		</form>
	);
}
