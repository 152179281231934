import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';

export default function TermsOfService() {
	return (
		<Layout>
			<Helmet>
				<title>Apize | Terms of Service</title>
			</Helmet>
			<div className="py-6 text-center mb-10">
				<div className="font-bold text-5xl text-gray-800">
					Terms of Service
				</div>
				<div className="mt-4 font-semibold text-lg">
					Updated March 4, 2023
				</div>
			</div>
			<div className="text-lg max-w-4xl mx-auto">
				<p>
					Welcome to APIZE LLC! We provide online AI-based APIs that
					our users can access. By accessing and using our services,
					you agree to the following terms and conditions:
				</p>
				<p className="px-8 py-6">
					<ol className="list-decimal list-inside">
						<li className="my-5">
							<span className="font-bold">
								Use of Our Services
							</span>
							<p className="py-4">
								APIZE LLC provides a range of online AI-based
								APIs that users can access and use. By using our
								services, you agree to use them only for lawful
								purposes and in compliance with all applicable
								laws, rules, and regulations. You also agree not
								to use our services in any way that could harm
								our company or any third party.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">User Accounts</span>
							<p className="py-4">
								In order to access our services, you may need to
								create a user account. You are responsible for
								maintaining the confidentiality of your account
								information, including your username and
								password. You are also responsible for all
								activities that occur under your account.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Payment</span>
							<p className="py-4">
								Some of our services may require payment. By
								using these services, you agree to pay all fees
								associated with them in a timely manner. We may
								modify our fees at any time, but we will provide
								advance notice of any changes.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Intellectual Property
							</span>
							<p className="py-4">
								Our services and all content included in them,
								such as text, graphics, logos, images, and
								software, are the property of APIZE LLC or its
								licensors and are protected by copyright and
								other intellectual property laws. You may not
								copy, modify, distribute, or otherwise use any
								of this content without our prior written
								consent.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Privacy</span>
							<p className="py-4">
								We take your privacy seriously and will handle
								your personal information in accordance with our
								Privacy Policy, which is available on our
								website.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Disclaimer of Warranties
							</span>
							<p className="py-4">
								Our services are provided "as is" and without
								warranties of any kind, whether express or
								implied. We make no representations or
								warranties about the accuracy, reliability,
								completeness, or timeliness of our services or
								any content included in them.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Limitation of Liability
							</span>
							<p className="py-4">
								In no event shall APIZE LLC be liable for any
								damages, including without limitation, direct,
								indirect, incidental, special, consequential, or
								punitive damages, arising out of or in
								connection with your use of our services.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Indemnification</span>
							<p className="py-4">
								You agree to indemnify and hold APIZE LLC and
								its affiliates, officers, agents, and employees
								harmless from any claims, liabilities, damages,
								losses, or expenses, including reasonable
								attorneys' fees, arising out of or in connection
								with your use of our services or any violation
								of these terms and conditions.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Termination</span>
							<p className="py-4">
								We may terminate your access to our services at
								any time for any reason, including without
								limitation, if you violate these terms and
								conditions. Upon termination, your right to use
								our services will immediately cease.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Governing Law</span>
							<p className="py-4">
								These terms and conditions shall be governed by
								and construed in accordance with the laws of the
								State of Indiana, without giving effect to any
								principles of conflicts of law.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Changes to Terms of Service
							</span>
							<p className="py-4">
								We may modify these terms and conditions at any
								time, and any such modifications will be
								effective immediately upon posting on our
								website. You are responsible for regularly
								reviewing these terms and conditions to ensure
								that you are aware of any changes.
							</p>
						</li>
					</ol>
				</p>
				<p>
					If you have any questions or concerns about these terms and
					conditions, please contact us at{' '}
					<a
						href="mailto:apize@cdd.email"
						className="font-semibold text-blue-600"
					>
						apize@cdd.email
					</a>
					.
				</p>
			</div>
		</Layout>
	);
}
