let baseurl = defaultBaseUrl();

function defaultBaseUrl(): string {
	if (window.location.hostname === 'localhost')
		return 'http://localhost:8080';
	return 'https://backend.apize.dev';
}

export function setBaseUrl(base: string) {
	baseurl = base;
}

export async function get<T>(
	url: string,
	token?: string | Promise<string | undefined>
): Promise<T> {
	const normalized_token = await normalizeTokenPromise(token);
	return handleApiFetch(
		fetch(`${baseurl}${url}`, {
			method: 'GET',
			headers: normalized_token
				? { Authorization: `Bearer ${normalized_token}` }
				: undefined,
		})
	);
}

export async function post<T>(
	url: string,
	token?: string | Promise<string | undefined>,
	body?: any
): Promise<T> {
	const normalized_token = await normalizeTokenPromise(token);
	return handleApiFetch(
		fetch(`${baseurl}${url}`, {
			method: 'POST',
			headers: normalized_token
				? { Authorization: `Bearer ${normalized_token}` }
				: undefined,
			body: JSON.stringify(body ?? {}),
		})
	);
}

function normalizeTokenPromise(
	token: string | Promise<string | undefined> | undefined
): Promise<string | undefined> {
	if (!token || typeof token == 'string') return Promise.resolve(token);
	return token;
}

async function handleApiFetch<T>(fetchRes: Promise<Response>): Promise<T> {
	const res = await fetchRes.then((r) => r.json());
	if (!res || typeof res !== 'object')
		throw new Error('malformed api response');
	if ('error' in res) throw new Error(res.error);
	if (!('data' in res))
		throw new Error('malformed api reponse, missing `data` field');
	return res.data as T;
}
