import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Token } from '../proto/tokens';
import { get, post } from '../util/api';
import TokenDeleteDialog from './token-delete-dialog';

export default function Tokens() {
	const { getIdTokenClaims } = useAuth0();
	const [tokens, setTokens] = useState<Token[] | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [deletingToken, setDeletingToken] = useState<Token | undefined>(
		undefined
	);

	useEffect(() => {
		(async () => {
			setLoading(true);
			setError(undefined);
			try {
				const token = await getIdTokenClaims().then((t) => t?.__raw);
				const res = await get<{ tokens: Token[] }>('/tokens', token);
				setTokens(res.tokens);
			} catch (err) {
				setError(String(err));
			}
			setLoading(false);
		})();
	}, [getIdTokenClaims]);

	const deleteToken = (token: Token) => {
		setTokens(tokens?.filter((t) => t.id !== token.id));
		(async () => {
			try {
				const authToken = await getIdTokenClaims().then(
					(t) => t?.__raw
				);
				await post('/tokens/delete', authToken, { token_id: token.id });
			} catch (err) {
				console.error('Error deleting token: ', err);
			}
		})();
	};

	if (error) {
		return <div>Error getting tokens: {error}</div>;
	}

	if (loading) {
		return <div>Loading tokens...</div>;
	}

	const describeScopes = (scopes: string[]): string => {
		if (scopes.length === 0) return 'None';
		if (scopes.includes('*')) return 'All';
		return scopes.join(',');
	};

	const formatLastUsed = (unix: number | null): string => {
		if (!unix) return 'Never';
		return new Date(unix * 1000).toLocaleString();
	};

	return (
		<>
			{!!deletingToken && (
				<TokenDeleteDialog
					onCancel={() => setDeletingToken(undefined)}
					onDelete={() => {
						setDeletingToken(undefined);
						deleteToken(deletingToken);
					}}
				/>
			)}
			<div className="mt-8 flow-root">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Scopes
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Last Used
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Expires
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
										>
											Options
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{tokens?.map((token) => (
										<tr key={token.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
												{token.name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{describeScopes(token.scopes)}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{formatLastUsed(
													token.last_used_at
												)}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{token.expires_at === null && (
													<span>Never</span>
												)}
												{token.expires_at !== null && (
													<span>
														{token.expires_at}
													</span>
												)}
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												{/* <a
												href="#"
												className="text-indigo-600 hover:text-indigo-900"
											>
												Edit
											</a>
											&nbsp; */}
												<a
													href="#"
													className="text-red-600 hover:text-red-900 ml-2"
													onClick={() =>
														setDeletingToken(token)
													}
												>
													Delete
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
