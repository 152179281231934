import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrivateRoute } from './components/route-private';
import './globals.css';
import NotFoundPage from './pages/404';
import Billing from './pages/billing/billing';
import SetupPaymentMethodPage from './pages/billing/payment-method-setup';
import PaymentMethodStatus from './pages/billing/payment-method-status';
import Home from './pages/home/home';
import PrivacyPolicy from './pages/policy/privacy-policy';
import TermsOfService from './pages/policy/terms-of-service';
import Pricing from './pages/pricing/pricing';
import NewToken from './pages/tokens/new-token';
import Tokens from './pages/tokens/tokens';

const AUTH0_DOMAIN = 'apize.us.auth0.com';
const AUTH0_CLIENT_ID = 'GD7H0yy95LCFJJTx1tkDNmv45GF3g0B6';

function Private(child: JSX.Element): JSX.Element {
	return <PrivateRoute>{child}</PrivateRoute>;
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: 'pricing',
		element: <Pricing />,
	},
	{
		path: 'tokens',
		element: Private(<Tokens />),
	},
	{
		path: 'tokens/new',
		element: Private(<NewToken />),
	},
	{
		path: 'billing',
		element: Private(<Billing />),
	},
	{
		path: 'billing/payment-method-setup',
		element: Private(<SetupPaymentMethodPage />),
	},
	{
		path: 'billing/payment-method-status',
		element: Private(<PaymentMethodStatus />),
	},
	{
		path: 'policy/terms',
		element: <TermsOfService />,
	},
	{
		path: 'policy/privacy',
		element: <PrivacyPolicy />,
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Auth0Provider
			domain={AUTH0_DOMAIN}
			clientId={AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin,
			}}
		>
			<RouterProvider router={router} />
		</Auth0Provider>
	</React.StrictMode>
);
