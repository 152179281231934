import { DocumentTextIcon } from '@heroicons/react/20/solid';

export default function BillsList() {
	return (
		<div className="text-center">
			<DocumentTextIcon
				className="mx-auto h-12 w-12 text-gray-400"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				aria-hidden="true"
			/>
			<h3 className="mt-2 text-sm font-semibold text-gray-900">
				No Bills
			</h3>
			<p className="mt-1 text-sm text-gray-500">
				You don't have any billing history yet.
			</p>
		</div>
	);
}
