import Footer from './footer';
import Header from './header';

export default function Layout({
	nopadding,
	children,
}: {
	nopadding?: boolean;
	children: JSX.Element | JSX.Element[];
}) {
	const paddingTop = nopadding ? '' : 'pt-28';
	return (
		<>
			<Header />
			<div className={`${paddingTop} h-screen`}>
				{Boolean(nopadding) && children}
				{!nopadding && (
					<div className="mx-auto max-w-7xl px-6 mb-32">
						{children}
					</div>
				)}
				<Footer />
			</div>
		</>
	);
}
