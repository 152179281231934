import Layout from '../../components/layout';
import { Helmet } from 'react-helmet';
import PricingTable from '../../components/pricing-table';

export default function Pricing() {
	return (
		<Layout>
			<Helmet>
				<title>Apize | Pricing</title>
			</Helmet>
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center mb-12 border-b-2 border-solid border-gray-200 pb-4">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold leading-6 text-gray-900">
							Pricing
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Pricing details for each API provided by Apize.
						</p>
					</div>
				</div>
				<div>
					<PricingTable />
				</div>
				<div className="text-sm py-4 text-gray-500">
					Note: If usage in any month is less than $0.50 USD, you will
					not be charged.
				</div>
			</div>
		</Layout>
	);
}
