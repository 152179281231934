import { useAuth0 } from '@auth0/auth0-react';
import { CheckIcon, ClipboardIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CreateTokenResponse } from '../proto/tokens';
import { post } from '../util/api';

export default function TokenForm() {
	const { getIdTokenClaims } = useAuth0();
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [result, setResult] = useState<CreateTokenResponse | null>(null);

	const [name, setName] = useState('');
	const [scopes, setScopes] = useState([] as string[]);

	const [copied, setCopied] = useState(false);

	const createToken = async () => {
		// If we're already submitting, don't double-submit
		if (submitting) return;

		setSubmitting(true);
		setError(null);
		try {
			const authToken = await getIdTokenClaims().then((t) => t?.__raw);
			const res = await post<CreateTokenResponse>(
				'/tokens/create',
				authToken,
				{
					name: name,
					scopes: scopes,
				}
			);
			setResult(res);
		} catch (err) {
			setError(String(err));
		}
		setSubmitting(false);
	};

	if (result) {
		return (
			<div>
				<h1 className="text-xl font-semibold leading-6 text-gray-900 mb-6">
					Token Created
				</h1>
				<p className="mb-4">
					Here's your secret API token. Copy it now, you won't be able
					to view it again.
				</p>
				<div className="mb-8">
					<label
						htmlFor="email"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						API Token
					</label>
					<div className="mt-2 flex rounded-md shadow-sm">
						<div className="relative flex flex-grow items-stretch focus-within:z-10">
							<input
								type="text"
								className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								disabled
								value={result.secret_key}
							/>
						</div>
						<button
							type="button"
							className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							onClick={() => {
								navigator.clipboard.writeText(
									result.secret_key
								);
								setCopied(true);
							}}
						>
							{!copied ? (
								<>
									<ClipboardIcon
										className="-ml-0.5 h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
									Copy
								</>
							) : (
								<>
									<CheckIcon
										className="-ml-0.5 h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
									Copied
								</>
							)}
						</button>
					</div>
				</div>
				<Link
					to="/tokens"
					className="rounded-md bg-indigo-600 px-3.5 py-2.5 mt-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					Continue &nbsp;
					<span aria-hidden="true">&rarr;</span>
				</Link>
			</div>
		);
	}

	return (
		<div>
			<label
				htmlFor="name"
				className="block text-sm font-medium text-gray-700"
			>
				Token Name
			</label>
			<div className="mt-1">
				<input
					type="text"
					className="block w-full mb-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
					placeholder="My API Token"
					onInput={(event) =>
						setName((event.target as HTMLInputElement).value)
					}
				/>
			</div>
			<p
				className="mt-2 mb-4 text-sm text-gray-500"
				id="email-description"
			>
				Give the token a memorable name.
			</p>
			<button
				type="button"
				className="inline-flex items-center mb-3 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				onClick={createToken}
			>
				Create Token
			</button>
		</div>
	);
}
