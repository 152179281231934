import { useAuth0 } from '@auth0/auth0-react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import EnablePaymentsNotice from '../../components/enable-payments-notice';
import Layout from '../../components/layout';
import TokensList from '../../components/tokens';
import { get } from '../../util/api';

export default function Tokens() {
	const navigate = useNavigate();

	const { getIdTokenClaims } = useAuth0();
	const [paymentsEnabled, setPaymentsEnabled] = useState<boolean | null>(
		null
	);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();

	useEffect(() => {
		(async () => {
			setLoading(true);
			setError(undefined);
			try {
				const token = await getIdTokenClaims().then((t) => t?.__raw);
				const res = await get<{ payment_methods: PaymentMethod[] }>(
					'/paymentmethods/list',
					token
				);
				setPaymentsEnabled(res.payment_methods.length > 0);
			} catch (err) {
				setError(String(err));
			}
			setLoading(false);
		})();
	}, [getIdTokenClaims]);

	const banner = paymentsEnabled === false ? <EnablePaymentsNotice /> : <></>;

	return (
		<Layout>
			<Helmet>
				<title>Apize | API Tokens</title>
			</Helmet>
			{banner}
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold leading-6 text-gray-900">
							API Tokens
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Manage the API tokens connected to your account.
						</p>
					</div>
					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<button
							type="button"
							className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-40"
							disabled={!paymentsEnabled}
							onClick={() => navigate('/tokens/new')}
						>
							New Token
						</button>
					</div>
				</div>
				<TokensList />
			</div>
		</Layout>
	);
}
