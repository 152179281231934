import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export default function EnablePaymentsNotice() {
	return (
		<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-10">
			<div className="flex">
				<div className="flex-shrink-0">
					<ExclamationTriangleIcon
						className="h-5 w-5 text-yellow-400"
						aria-hidden="true"
					/>
				</div>
				<div className="ml-3">
					<p className="text-sm text-yellow-700">
						You need to add a payment method before creating an API
						token.{' '}
						<Link
							to="/billing/payment-method-setup"
							className="font-medium text-yellow-700 underline hover:text-yellow-600"
						>
							Click here to setup payments.
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
}
