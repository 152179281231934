import { useAuth0 } from '@auth0/auth0-react';
import Layout from './layout';

export function PrivateRoute({ children }: { children: any }) {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	if (isLoading) {
		return (
			<Layout>
				<span>Loading...</span>
			</Layout>
		);
	}
	if (!isAuthenticated) {
		loginWithRedirect();
		return <></>;
		// return <Navigate to="/" />;
	}
	return children;
}
