import { useAuth0 } from '@auth0/auth0-react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	ChartData,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { get } from '../util/api';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface DailyUsage {
	year: number;
	month: number;
	day: number;
	usage_usd_cent_hundredths: number;
}

export default function UsageChart() {
	const { getIdTokenClaims } = useAuth0();
	const [options, setOptions] = useState<ChartOptions<'bar'> | undefined>(
		undefined
	);
	const [data, setData] = useState<
		ChartData<'bar', number[], string> | undefined
	>(undefined);

	useEffect(() => {
		(async () => {
			try {
				const token = await getIdTokenClaims().then((t) => t?.__raw);
				const { days } = await get<{ days: DailyUsage[] }>(
					'/usage/daily',
					token
				);
				days.reverse();
				setOptions(optionsFromUsage(days));
				setData(dataFromUsage(days));
			} catch (err) {
				console.error(err);
				// setError(String(err));
			}
		})();
	}, [getIdTokenClaims]);

	return (
		<div className="max-w-lg mx-auto">
			{options && data ? (
				<Bar options={options} data={data} />
			) : (
				<span>Loading...</span>
			)}
		</div>
	);
}

function optionsFromUsage(usagePerDay: DailyUsage[]): ChartOptions<'bar'> {
	// const maxUsage = Math.max(...usagePerDay.map((usage) => usage.usage_usd_cent_hundredths));
	// const maxYLabel = Math.max(maxUsage * 1.2, 500);

	const options: ChartOptions<'bar'> = {
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
				position: 'bottom',
			},
			tooltip: {
				callbacks: {
					label: (item) =>
						`Usage: $${((item.raw as number) / 10000).toFixed(4)}`,
				},
			},
		},
		responsive: true,
		scales: {
			x: {},
			y: {
				ticks: {
					callback: (label, index, ticks) => {
						return `$${((label as number) / 10000).toFixed(4)}`;
					},
				},
				// max: maxYLabel,
			},
		},
	};
	return options;
}

function dataFromUsage(
	usagePerDay: DailyUsage[]
): ChartData<'bar', number[], string> {
	const data: ChartData<'bar', number[], string> = {
		labels: usagePerDay.map((usageDay) => {
			const MONTHS = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];
			return [
				usageDay.day < 10 ? `0${usageDay.day}` : usageDay.day,
				MONTHS[usageDay.month - 1],
			].join(' ');
		}),
		datasets: [
			{
				label: 'Usage',
				data: usagePerDay.map(
					(usageDay) => usageDay.usage_usd_cent_hundredths
				),
				backgroundColor: '#08F', // 07ba51
				// barThickness: 24,
				borderRadius: {
					topLeft: 3,
					topRight: 3,
					bottomLeft: 0,
					bottomRight: 0,
				},
			},
		],
	};
	return data;
}
