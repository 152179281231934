import clsx from 'clsx';
import Highlight, { defaultProps } from 'prism-react-renderer';
import { useEffect, useState } from 'react';
import GoLogo from './images/go.svg';
import NodeLogo from './images/node.svg';

interface Lang {
	key: string;
	tab: JSX.Element;
}

interface Snippet {
	lang: string;
	code: string;
}

const codeSnippets: Snippet[] = [
	{
		lang: 'javascript',
		code: [
			`const { Client } = require('apize');`,
			``,
			`const apize = new Client('YOUR_TOKEN');`,
			``,
			`const { summary } = await apize.text.summarize({`,
			`    text: 'The quick brown fox jumped over the lazy dog',`,
			`});`,
		].join('\n'),
	},
	{
		lang: 'go',
		code: [
			'import "github.com/apizedev/apize-go"',
			'',
			'client := apize.New("YOUR_TOKEN")',
			'',
			'ctx := context.Background()',
			'res, _ := client.Summarize(ctx, &apize.SummarizeRequest{',
			'    Text: "The quick brown fox jumped over the lazy dog"',
			'})',
		].join('\n'),
	},
];

export default function CodeSnippetsCard() {
	const languages: Lang[] = [
		{
			key: 'javascript',
			tab: (
				<>
					<img src={NodeLogo} className=" h-7 inline-block" />
					<span className="ml-1">JavaScript</span>
				</>
			),
		},
		{
			key: 'go',
			tab: (
				<>
					<img src={GoLogo} className=" h-7 inline-block" />
					<span className="ml-1">Go</span>
				</>
			),
		},
	];

	const [selectedLanguage, setSelectedLanguage] = useState(languages[0].key);
	const [code, setCode] = useState<string>('');

	useEffect(() => {
		setCode(
			codeSnippets.find((snippet) => snippet.lang === selectedLanguage)
				?.code ?? ''
		);
	}, [selectedLanguage]);

	return (
		<div className="bg-gray-700 rounded-md shadow-lg overflow-hidden shadow-gray-400 border border-gray-800">
			<div className="h-14">
				{languages.map((lang) => (
					<div
						key={lang.key}
						onClick={() => setSelectedLanguage(lang.key)}
						className={classNames({
							'border-t-4 border-solid inline-block py-3 pl-3 pr-4 text-white cursor-pointer select-none transition-all':
								true,
							'border-t-transparent hover:bg-gray-800':
								selectedLanguage !== lang.key,
							'border-t-indigo-500 bg-gray-900':
								selectedLanguage === lang.key,
						})}
					>
						{lang.tab}
					</div>
				))}
			</div>
			<div className="bg-gray-900 pt-6">
				<div className="flex items-start px-1 text-sm">
					<div
						aria-hidden="true"
						className="select-none border-r border-slate-300/5 w-6 pr-2 font-mono text-gray-400 text-right"
					>
						{Array.from({
							length: code.split('\n').length,
						}).map((_, index) => (
							<div key={index} className="text-right">
								{(index + 1).toString()}
							</div>
						))}
					</div>
					<Highlight
						{...defaultProps}
						code={code}
						language={selectedLanguage as any}
						theme={undefined}
					>
						{({
							className,
							style,
							tokens,
							getLineProps,
							getTokenProps,
						}) => (
							<pre
								className={clsx(
									className,
									'flex overflow-x-auto pb-6'
								)}
								style={style}
							>
								<code className="px-4">
									{tokens.map((line, lineIndex) => (
										<div
											key={lineIndex}
											{...getLineProps({ line })}
										>
											{line.map((token, tokenIndex) => (
												<span
													key={tokenIndex}
													{...getTokenProps({
														token,
													})}
												/>
											))}
										</div>
									))}
								</code>
							</pre>
						)}
					</Highlight>
				</div>
			</div>
		</div>
	);
}

function classNames(map: { [key: string]: boolean }): string {
	return Object.keys(map)
		.filter((key) => map[key])
		.join(' ');
}
