import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';

export default function PrivacyPolicy() {
	return (
		<Layout>
			<Helmet>
				<title>Apize | Privacy Policy</title>
			</Helmet>
			<div className="py-6 text-center mb-10">
				<div className="font-bold text-5xl text-gray-800">
					Privacy Policy
				</div>
				<div className="mt-4 font-semibold text-lg">
					Updated March 4, 2023
				</div>
			</div>
			<div className="text-lg max-w-4xl mx-auto">
				<p>
					At APIZE LLC, we are committed to protecting the privacy of
					our users. This Privacy Policy explains how we collect, use,
					and disclose personal information that we receive through
					our website and services.
				</p>
				<p className="px-8 py-6">
					<ol className="list-decimal list-inside">
						<li className="my-5">
							<span className="font-bold">
								Information We Collect
							</span>
							<p className="py-4">
								We collect personal information from users in
								order to provide our services. The types of
								information we collect may include:
							</p>
							<p className="py-4">
								<ul className="list-disc list-outside ml-4">
									<li>
										Name, email address, and other contact
										information
									</li>
									<li>
										IP address, browser type, and other
										technical information about your device
										and use of our services
									</li>
									<li>
										Billing information for payment
										processing
									</li>
								</ul>
							</p>
							<p className="py-4">
								We may also collect and use non-personal
								information, such as aggregate data and usage
								statistics, to improve our services.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								How We Use Information
							</span>
							<p className="py-4">
								We use personal information to provide and
								improve our services, to communicate with users,
								and to process payments. We may also use
								information to respond to user inquiries, to
								comply with legal obligations, and to enforce
								our policies.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								How We Share Information
							</span>
							<p className="py-4">
								We do not sell or rent personal information to
								third parties. We may share personal information
								with third-party service providers who assist us
								in providing our services, such as payment
								processors and analytics providers. We may also
								share information with law enforcement or other
								third parties when required by law or to protect
								our rights or the rights of others.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Security</span>
							<p className="py-4">
								We take reasonable measures to protect personal
								information from loss, theft, and unauthorized
								access, disclosure, alteration, and destruction.
								However, no data transmission or storage system
								can be guaranteed to be 100% secure.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Cookies and Tracking Technologies
							</span>
							<p className="py-4">
								We use cookies and other tracking technologies
								to enhance user experience and analyze website
								usage. Users may adjust their browser settings
								to block or limit the use of cookies and other
								tracking technologies, but this may affect the
								functionality of our services.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Children's Privacy
							</span>
							<p className="py-4">
								Our services are not intended for children under
								the age of 13, and we do not knowingly collect
								personal information from children under the age
								of 13.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">Your Choices</span>
							<p className="py-4">
								Users may update or delete their personal
								information by contacting us at the email
								address provided below. Users may also
								unsubscribe from marketing communications at any
								time by following the instructions in such
								communications.
							</p>
						</li>
						<li className="my-5">
							<span className="font-bold">
								Changes to this Privacy Policy
							</span>
							<p className="py-4">
								We may update this Privacy Policy from time to
								time. The date at the top of this policy
								indicates the date of the latest revision. Users
								should check this page periodically for changes.
							</p>
						</li>
					</ol>
				</p>
				<p>
					If you have any questions or concerns about this privacy
					policy, please contact us at{' '}
					<a
						href="mailto:apize@cdd.email"
						className="font-semibold text-blue-600"
					>
						apize@cdd.email
					</a>
					.
				</p>
			</div>
		</Layout>
	);
}
