import { useState, useEffect } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { stripePromise } from '../../util/stripe';
import Layout from '../../components/layout';
import { useNavigate } from 'react-router-dom';
import CardSuccessModal from '../../components/card-success-modal';
import CardErrorModal from '../../components/card-error-modal';

export default function PaymentMethodStatus() {
	return (
		<Elements stripe={stripePromise}>
			<PaymentMethodStatusInner />
		</Elements>
	);
}

function PaymentMethodStatusInner() {
	const stripe = useStripe();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!stripe) {
			return;
		}

		// Retrieve the "setup_intent_client_secret" query parameter appended to
		// your return_url by Stripe.js
		const clientSecret = new URLSearchParams(window.location.search).get(
			'setup_intent_client_secret'
		);
		if (!clientSecret) {
			navigate('/billing');
			return;
		}

		// Retrieve the SetupIntent
		stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
			if (!setupIntent) {
				setError('Something went wrong. Please try again.');
				setSuccess(false);
				return;
			}
			// Inspect the SetupIntent `status` to indicate the status of the payment
			// to your customer.
			//
			// Some payment methods will [immediately succeed or fail][0] upon
			// confirmation, while others will first enter a `processing` state.
			//
			// [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
			switch (setupIntent.status) {
				case 'succeeded':
				case 'processing':
					setSuccess(true);
					break;

				case 'requires_payment_method':
					// Redirect your user back to your payment page to attempt collecting
					// payment again
					setError(
						'Failed to process payment details. Please try another payment method.'
					);
					setSuccess(false);
					break;
			}
		});
	}, [stripe]);

	if (success === null) {
		return (
			<Layout>
				<div>Loading...</div>
			</Layout>
		);
	} else if (success === true) {
		return (
			<Layout>
				<CardSuccessModal />
			</Layout>
		);
	} else {
		return (
			<Layout>
				<CardErrorModal message={error!} />
			</Layout>
		);
	}
}
