import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import TokenForm from '../../components/token-form';

export default function NewToken() {
	return (
		<Layout>
			<Helmet>
				<title>Apize | New API Token</title>
			</Helmet>
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center mb-12 border-b-2 border-solid border-gray-200 pb-4">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold leading-6 text-gray-900">
							New API Token
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Create a new token to access Apize's set of APIs.
						</p>
					</div>
				</div>
				<div className="max max-w-xl mx-auto">
					<TokenForm />
				</div>
			</div>
		</Layout>
	);
}
