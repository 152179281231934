import { useAuth0 } from '@auth0/auth0-react';
import {
	Elements,
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import CardForm from '../../components/card-form';
import { get } from '../../util/api';
import { stripePromise } from '../../util/stripe';

export default function SetupPaymentMethodPage() {
	const { getIdTokenClaims } = useAuth0();
	const [clientSecret, setClientSecret] = useState<string | null>(null);
	const [error, setError] = useState<string | null>();

	useEffect(() => {
		(async () => {
			try {
				const token = await getIdTokenClaims().then((t) => t?.__raw);
				const res = await get<{ client_secret: string }>(
					'/paymentmethods/setup',
					token
				);
				setClientSecret(res.client_secret);
			} catch (err) {
				setError(String(err));
			}
		})();
	}, []);

	if (!clientSecret) {
		return (
			<Layout>
				<div>Loading...</div>
			</Layout>
		);
	}
	const options = {
		clientSecret,
	};
	return (
		<Layout>
			<Elements stripe={stripePromise} options={options}>
				<div className="px-4 sm:px-6 lg:px-8">
					<div className="sm:flex sm:items-center mb-12 border-b-2 border-solid border-gray-200 pb-4">
						<div className="sm:flex-auto">
							<h1 className="text-xl font-semibold leading-6 text-gray-900">
								Add a Payment Method
							</h1>
							<p className="mt-2 text-sm text-gray-700">
								Your payment method will be charged monthly, if
								you have any billable activity.
							</p>
						</div>
					</div>
					<div className="max max-w-xl mx-auto">
						<CardForm />
					</div>
				</div>
			</Elements>
		</Layout>
	);
}
